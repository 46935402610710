
 
var slideIndex =1;

//showSlides(slideIndex);
// $(function()
// {
//     showSlides(slideIndex);
// });

  
  function plusSlides(n) {
      
    showSlides(slideIndex += n);
  }
  
  function currentSlide(n) {
    showSlides(slideIndex = 1);
  }
  
  function showSlides(n) {
    

    var i;
    var slides = document.getElementsByClassName("mySlides");
    var dots = document.getElementsByClassName("dot");
   
    if (n > slides.length) {slideIndex = 0}
    if (n < 0) {slideIndex = slides.length}
    for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
    }
    for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace("active", "");
    }
  
    slides[slideIndex-1].style.display = "block";
    

    dots[slideIndex-1].className += "active";
     
  }